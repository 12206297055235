import React from "react"
import styled from "styled-components"
import Image, { FluidObject } from "gatsby-image"

// Styles
import mq from "../styles/breakpoints"

interface IClient {
  alt: string
  url: FluidObject | undefined
}
interface IProps {
  clients: IClient[]
}

const Clients = ({ clients }: IProps) => (
  <Container>
    <div>
      <Title>Opdrachtgevers:</Title>
    </div>
    {clients.map((client, i) => (
      <div key={`client-${i}`}>
        <ClientLogo fluid={client.url} alt={client.alt} />
      </div>
    ))}
  </Container>
)

const Container = styled.section`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 32px 24px;

  > div {
    width: 25%;
    min-width: 25%;
    padding: 12px;

    ${mq.from.breakpoint.S`
      padding: 24px;
    `}

    ${mq.from.breakpoint.M`
      width: 20%;
      min-width: 20%;
      padding: 24px 32px;
    `}

    ${mq.from.breakpoint.L`
      padding: 32px 48px;
    `}

    :first-child {
      min-width: 100%;
      padding-right: 0;
      padding-bottom: 8px;
      text-align: center;

      ${mq.from.breakpoint.S`
        padding-bottom: 0;
      `}

      ${mq.from.breakpoint.L`
        width: 20%;
        min-width: 20%;
        padding-bottom: 32px;
        text-align: left;
      `}
    }
  }
`

const Title = styled.h5`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 0;

  ${mq.from.breakpoint.M`
    font-size: 18px;
  `}
`

const ClientLogo = styled(Image)`
  img {
    margin-bottom: 0;
  }
`

export default Clients
