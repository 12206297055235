import React from "react"
import styled from "styled-components"

// Styling
import colors from "../styles/colors"
import mq from "../styles/breakpoints"
import textStyles from "../styles/textStyles"

// Components
import ContentWrapper from "./ContentWrapper"
import Button from "./atoms/Button"

interface IProps {
  title: string
  text: string
  email: string
  phone: {
    label: string
    number: string
  }
  privacyPolicy: string
  kvk: string
  btwId: string
}

const Footer = ({
  title,
  text,
  email,
  phone,
  privacyPolicy,
  kvk,
  btwId,
}: IProps) => (
  <OuterContainer>
    <Container>
      <TopContentWrapper>
        <Title>{title}</Title>
        <Text dangerouslySetInnerHTML={{ __html: text }} />
        <ButtonWrapper>
          <Button label={phone.label} href={`tel:${phone.number}`} />
          <Button label={email} href={`mailto:${email}`} />
        </ButtonWrapper>
      </TopContentWrapper>
      <ContentWrapper>
        <BottomContentWrapper>
          <a href={privacyPolicy} target="_blank">
            Privacybeleid
          </a>
          <Meta>
            <span>KvK: {kvk}</span>
            <span>BTW-ID: {btwId}</span>
            <span>&copy; 2008 - {new Date().getFullYear()} Agorazonta</span>
          </Meta>
        </BottomContentWrapper>
      </ContentWrapper>
    </Container>
  </OuterContainer>
)

const OuterContainer = styled.footer`
  background-color: ${colors.grey};
`

const Container = styled.div`
  color: ${colors.white};
  background-color: ${colors.black};
  padding: 40px 0;
  border-top-right-radius: 200px;

  ${mq.from.breakpoint.L`
    border-top-right-radius: 400px;
  `}
`

const TopContentWrapper = styled(ContentWrapper)`
  padding-top: 64px;
  padding-bottom: 64px;
`

const Title = styled.h2`
  ${textStyles.title};
  margin-bottom: 16px;

  ${mq.from.breakpoint.M`
    margin-bottom: 18px;
  `};
`

const Text = styled.div`
  max-width: 960px;
  margin-bottom: 32px;

  ${mq.from.breakpoint.M`
    margin-bottom: 40px;
  `}
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > a:first-child {
    margin-bottom: 24px;
  }

  ${mq.from.breakpoint.M`
    flex-direction: row;

    > a:first-child {
      margin-right: 24px;
      margin-bottom: 0;
    }
  `}
`

const BottomContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
  border-top: 1px solid ${colors.slate};
  font-size: 14px;

  > a {
    margin-bottom: 24px;
  }

  ${mq.from.breakpoint.M`
    flex-direction: row;
    justify-content: space-between;
    font-size: 16px;
  `}
`

const Meta = styled.div`
  opacity: 0.6;
  display: flex;
  flex-direction: column;

  ${mq.from.breakpoint.M`
    flex-direction: row;
    justify-content: space-between;
  `}

  > span {
    margin-bottom: 8px;

    ${mq.from.breakpoint.M`
      margin-right: 24px;
      margin-bottom: 0;
    `}

    :last-child {
      margin-right: 0;
    }
  }
`

export default Footer
