import { RefObject, Dispatch, SetStateAction } from "react"

type useDragAndScrollType = (
  ref: RefObject<HTMLElement>,
  listenersExist: boolean,
  setListenersExist: Dispatch<SetStateAction<boolean>>
) => [() => void]

const useDragAndScroll: useDragAndScrollType = (
  containerRef,
  listenersExist,
  setListenersExist
) => {
  const slider = containerRef.current

  let isDown = false
  let startX: any
  let scrollLeft: any

  const handleMouseDown = (e: MouseEvent) => {
    if (slider) {
      isDown = true
      startX = e.pageX - slider.offsetLeft
      scrollLeft = slider.scrollLeft
    }
  }

  const handleMouseLeave = () => {
    isDown = false
  }

  const handleMouseUp = () => {
    isDown = false
  }

  const handleMouseMove = (e: MouseEvent) => {
    if (!isDown) {
      return
    }
    if (slider) {
      const x = e.pageX - slider.offsetLeft
      const walk = x - startX
      slider.scrollLeft = scrollLeft - walk
    }
  }

  if (slider && !listenersExist) {
    setListenersExist(true)
    slider.addEventListener("mousedown", handleMouseDown)
    slider.addEventListener("mouseleave", handleMouseLeave)
    slider.addEventListener("mouseup", handleMouseUp)
    slider.addEventListener("mousemove", handleMouseMove)
  }

  const cleanUp = () => {
    if (slider) {
      setListenersExist(false)
      slider.removeEventListener("mousedown", handleMouseDown)
      slider.removeEventListener("mouseleave", handleMouseLeave)
      slider.removeEventListener("mouseup", handleMouseUp)
      slider.removeEventListener("mousemove", handleMouseMove)
    }
  }

  return [cleanUp]
}

export default useDragAndScroll
