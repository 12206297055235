import React from "react"
import styled from "styled-components"
import { Link } from "react-scroll"

// Styles
import colors from "../../styles/colors"

interface IProps {
  label: string
  to: string
}

const AnchorLink = ({ label, to }: IProps) => (
  <Link to={to} spy={true} smooth={true} duration={800}>
    <Label>{label}</Label>
  </Link>
)

const Label = styled.div`
  cursor: pointer;
  position: relative;
  padding-left: 28px;
  color: ${colors.red};
  font-weight: 700;
  line-height: 1;

  :hover {
    text-decoration: underline;
  }

  :before,
  :after {
    content: "";
    position: absolute;
    bottom: 12px;
    width: 12px;
    height: 3px;
    background-color: ${colors.red};
  }
  :before {
    left: 6px;
    transform: rotate(-45deg);
    transform-origin: right bottom;
  }

  :after {
    left: 0;
    transform: rotate(45deg);
    transform-origin: left bottom;
  }
`

export default AnchorLink
