import React from "react"
import styled from "styled-components"

// Styles
import colors from "../../styles/colors"

interface IProps {
  label: string
  href: string
}

const Button = ({ label, href }: IProps) => (
  <Container href={href}>{label}</Container>
)

const Container = styled.a`
  display: inline-block;
  padding: 16px 32px;
  color: ${colors.white};
  background-color: ${colors.red};
  text-decoration: none;
  font-weight: 700;
  border-radius: 32px;

  :hover {
    text-decoration: underline;
  }
`

export default Button
