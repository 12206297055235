import React, { useRef, useState, useEffect } from "react"
import styled from "styled-components"

// Hooks
import useDragAndScroll from "../utils/hooks/useDragAndScroll"

// Styles
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"
import colors from "../styles/colors"

// Components
import ContentWrapper from "./ContentWrapper"

interface IClientCard {
  icon: {
    alt: string
    url: string
  }
  title: string
  text: string
}
interface IProps {
  title: string
  cards: IClientCard[]
  email: string
}

const Services = ({ title, cards, email }: IProps) => {
  const ref = useRef<HTMLDivElement>(null)
  const [listenersExist, setListenersExist] = useState<boolean>(false)
  const [sliderContainer, setSliderContainer] = useState<any>({ current: null })

  const [cleanUp] = useDragAndScroll(
    sliderContainer,
    listenersExist,
    setListenersExist
  )

  useEffect(() => {
    setSliderContainer(ref)
    return cleanUp()
  }, [ref.current])

  return (
    <Container>
      <StyledContentWrapper>
        <Title>{title}</Title>
      </StyledContentWrapper>
      <ScrollContainer ref={ref}>
        <CardsWrapper>
          {cards.map((card, i) => (
            <Card key={`service-card-${i}`}>
              <Image src={card.icon.url} alt={card.icon.alt} />
              <CardTitle>{card.title}</CardTitle>
              <CardText dangerouslySetInnerHTML={{ __html: card.text }} />
            </Card>
          ))}
          <EndCard />
        </CardsWrapper>
      </ScrollContainer>
      <ContentWrapper>
        <CTA>
          Interesse? <a href={`mailto:${email}`}>{email}</a>
        </CTA>
      </ContentWrapper>
    </Container>
  )
}

const Container = styled.section`
  padding: 120px 0;

  ${mq.from.breakpoint.M`
    padding: 160px 0;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;

  ${mq.from.breakpoint.M`
    margin-bottom: 40px;
  `}
`

const Title = styled.h2`
  ${textStyles.title};
  margin-bottom: 0;

  ${mq.from.breakpoint.M`
    margin-bottom: 0;
  `}
`

const ScrollContainer = styled.div`
  cursor: grab;
  :active {
    cursor: grabbing;
  }
  margin-top: -18px;
  padding: 18px 0;
  user-select: none;
  overflow-x: scroll;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE 10+ */

  ::-webkit-scrollbar {
    /* Chrome/Safari/Webkit */
    width: 0px;
    background: transparent;
  }

  ${mq.from.breakpoint.M`
    margin-top: -24px;
    padding: 24px 0;
  `}
`

const CardsWrapper = styled.div`
  display: flex;
  margin-left: 24px;

  ${mq.from.px(1200)`
    position: relative;
    left: 50%;
    width: calc(100% - ((100% - 1200px) / 2));
    transform: translateX(-600px);
  `}
`

const Card = styled.div`
  min-width: 280px;
  margin-right: 24px;
  padding: 40px 24px 24px;
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.05);
  border-radius: 18px;

  ${mq.from.breakpoint.M`
    min-width: 320px;
    padding: 60px 24px 24px;
  `}

  ${mq.from.breakpoint.L`
    min-width: 400px;
    margin-right: 32px;
    padding: 80px 48px 48px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
    border-radius: 24px;
  `}
`

const EndCard = styled.div`
  min-width: 1px;
  min-height: 100%;
  background-color: ${colors.white};
  margin-left: -1px;
`

const Image = styled.img`
  width: 60px;
  margin-bottom: 32px;
`

const CardTitle = styled.h3`
  ${textStyles.subtitle};
`

const CardText = styled.div`
  p:last-child {
    margin-bottom: 0;
  }
`

const CTA = styled.div`
  margin-top: -12px;

  ${mq.from.breakpoint.M`
    margin-top: 0;
  `}

  > a {
    font-weight: 700;
    text-decoration: none;

    :hover {
      text-decoration: underline;
    }
  }
`

export default Services
