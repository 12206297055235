import React from "react"
import { graphql } from "gatsby"

// Components
import SEO from "../components/Seo"
import Header from "../components/Header"
import Clients from "../components/Clients"
import About from "../components/About"
import Services from "../components/Services"
import Testimonials from "../components/Testimonials"
import Footer from "../components/Footer"

const HomePage = ({ content }: any) => (
  <>
    <SEO
      title={content.metaData.title}
      description={content.metaData.description}
    />
    <Header {...content.header} />
    <Clients clients={content.clients} />
    <About {...content.about} />
    <Services {...content.services} />
    <Testimonials {...content.testimonials} />
    <Footer {...content.footer} />
  </>
)

export const query = graphql`
  query homePage {
    prismicHomePage {
      data {
        meta_title
        meta_description
        email
        phone_number
        phone_label
        header_title {
          text
        }
        header_intro {
          html
        }
        header_button_label
        header_image {
          alt
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        clients {
          client_logo {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        about_title {
          text
        }
        about_text {
          html
        }
        about_image {
          alt
          fluid {
            aspectRatio
            base64
            sizes
            src
            srcSet
            srcSetWebp
            srcWebp
          }
        }
        linkedin_url
        cv {
          url
        }
        services_title {
          text
        }
        services_cards {
          icon {
            alt
            url
          }
          card_title {
            text
          }
          card_text {
            html
          }
        }
        testimonials_title {
          text
        }
        testimonials_cards {
          testimonial_image {
            alt
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
          testimonial_text {
            text
          }
          testimonial_name
          testimonial_function
        }
        footer_title {
          text
        }
        footer_text {
          html
        }
        privacy_policy {
          url
        }
        kvk
        btw_id
      }
    }
  }
`

export default ({ data }: any) => {
  const rawData = data.prismicHomePage.data

  const metaData = {
    title: rawData.meta_title,
    description: rawData.meta_description,
  }

  const header = {
    title: rawData.header_title.text,
    text: rawData.header_intro.html,
    buttonLabel: rawData.header_button_label,
    image: {
      alt: rawData.header_image.alt,
      url: rawData.header_image.fluid,
    },
    email: rawData.email,
  }

  const clients = rawData.clients.map((client: any) => {
    return {
      alt: client.client_logo.alt,
      url: client.client_logo.fluid,
    }
  })

  const about = {
    title: rawData.about_title.text,
    text: rawData.about_text.html,
    image: {
      alt: rawData.about_image.alt,
      url: rawData.about_image.fluid,
    },
    linkedIn: rawData.linkedin_url,
    cv: rawData.cv.url,
  }

  const servicesCards = rawData.services_cards.map((card: any) => {
    return {
      icon: {
        alt: card.icon.alt,
        url: card.icon.url,
      },
      title: card.card_title.text,
      text: card.card_text.html,
    }
  })

  const services = {
    title: rawData.services_title.text,
    cards: servicesCards,
    email: rawData.email,
  }

  const testimonialCards = rawData.testimonials_cards.map(
    (testimonial: any) => {
      return {
        image: {
          alt: testimonial.testimonial_image.alt,
          url: testimonial.testimonial_image.fluid,
        },
        text: testimonial.testimonial_text.text,
        name: testimonial.testimonial_name,
        function: testimonial.testimonial_function,
      }
    }
  )

  const testimonials = {
    title: rawData.testimonials_title.text,
    cards: testimonialCards,
  }

  const footer = {
    title: rawData.footer_title.text,
    text: rawData.footer_text.html,
    email: rawData.email,
    phone: {
      label: rawData.phone_label,
      number: rawData.phone_number,
    },
    privacyPolicy: rawData.privacy_policy.url,
    kvk: rawData.kvk,
    btwId: rawData.btw_id,
  }

  const content = {
    metaData,
    header,
    clients,
    about,
    services,
    testimonials,
    footer,
  }

  return <HomePage content={content} />
}
