import React from "react"
import styled from "styled-components"
import Image, { FluidObject } from "gatsby-image"

// Styling
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import ContentWrapper from "./ContentWrapper"
import Icon from "./icons/Icon"

interface ITestimonialCard {
  image: {
    alt: string
    url: FluidObject | undefined
  }
  text: string
  name: string
  function: string
}

interface IProps {
  title: string
  cards: ITestimonialCard[]
}

const Testimonials = ({ title, cards }: IProps) => (
  <Container>
    <ContentWrapper>
      <Title>{title}</Title>
      {cards.map((card, i) => (
        <Card key={`testimonial-card-${i}`}>
          <Img fluid={card.image.url} alt={card.image.alt} />
          <div>
            <Quote>
              <StyledIcon
                icon="quote"
                width={50}
                height={50}
                color={colors.slate}
              />
              {card.text}
            </Quote>
            <Footer>
              {card.name} - <span>{card.function}</span>
            </Footer>
          </div>
        </Card>
      ))}
    </ContentWrapper>
  </Container>
)

const Container = styled.section`
  padding: 120px 0;
  background-color: ${colors.grey};

  ${mq.from.breakpoint.M`
    padding: 160px 0;
  `}
`

const Title = styled.h2`
  ${textStyles.title};
  margin-bottom: 32px;

  ${mq.from.breakpoint.M`
    margin-bottom: 40px;
  `}
`

const Card = styled.blockquote`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 0 0 24px;
  padding: 24px;
  background-color: ${colors.white};
  box-shadow: 0 0 18px 0 rgba(0, 0, 0, 0.05);
  border-radius: 18px;
  text-align: center;

  ${mq.from.breakpoint.M`
    flex-direction: row;
    margin-bottom: 32px;
    text-align: left;
    align-items: flex-start;
  `}

  ${mq.from.breakpoint.L`
    padding: 48px;
    box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.05);
    border-radius: 24px;
  `}
`

const Img = styled(Image)`
  border-radius: 50%;
  width: 80px;
  min-width: 80px;
  height: 80px;
  margin-bottom: 24px;

  ${mq.from.breakpoint.M`
    width: 120px;
    min-width: 120px;
    height: 120px;
    margin-right: 48px;
    margin-bottom: 0;
  `}

  ${mq.from.breakpoint.L`
    width: 140px;
    min-width: 140px;
    height: 140px;
  `}
`

const Quote = styled.p`
  font-size: 20px;

  ${mq.from.breakpoint.M`
    font-size: 22px;
  `}
`

const StyledIcon = styled(Icon)`
  margin-right: 12px;
  opacity: 0.2;
  transform: translateY(8px);
`

const Footer = styled.footer`
  color: ${colors.slate};

  > span {
    color: ${colors.black};
    font-weight: 700;
  }
`

export default Testimonials
