import React from "react"
import styled from "styled-components"

// Styles
import colors from "../../styles/colors"

interface IProps {
  label: string
  href: string
  target?: "_blank" | "self"
  type?: "download" | "external"
}

const ExternalLink = ({
  label,
  href,
  target = "_blank",
  type = "external",
}: IProps) => (
  <Container href={href} target={target} rel="noopener">
    <Icon type={type}>
      <span />
    </Icon>
    {label}
  </Container>
)

const Container = styled.a`
  display: flex;
  align-items: center;
  font-weight: 700;
  line-height: 1;
  text-decoration: none;

  :hover {
    text-decoration: underline;
  }
`

interface IIcon {
  type: "external" | "download"
}
const Icon = styled.div<IIcon>`
  position: relative;
  width: 12px;
  height: 12px;
  margin-right: 16px;

  :before,
  :after,
  > span {
    position: absolute;
    top: 0;
    background-color: ${colors.red};
  }

  :before {
    content: "";
    right: 0;
    width: 3px;
    height: 12px;
  }

  :after {
    content: "";
    right: 0;
    width: 12px;
    height: 3px;
  }

  > span {
    right: 5px;
    display: block;
    width: 3px;
    height: 12px;
    transform: rotate(45deg);
  }

  ${({ type }) =>
    type === "download" &&
    `
    transform: rotate(135deg);
  `}
`

export default ExternalLink
