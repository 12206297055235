import React from "react"
import styled from "styled-components"
import { Element } from "react-scroll"
import Image, { FluidObject } from "gatsby-image"

// Styles
import colors from "../styles/colors"
import textStyles from "../styles/textStyles"
import mq from "../styles/breakpoints"

// Components
import ContentWrapper from "./ContentWrapper"
import ExternalLink from "./atoms/ExternalLink"

interface IProps {
  title: string
  text: string
  image: {
    alt: string
    url: FluidObject | undefined
  }
  linkedIn: string
  cv: string
}

const About = ({ title, text, image, linkedIn, cv }: IProps) => (
  <Element name="about">
    <Container>
      <StyledContentWrapper>
        <Img fluid={image.url} alt={image.alt} />
        <TextBlock>
          <Title>{title}</Title>
          <div dangerouslySetInnerHTML={{ __html: text }} />
          <LinksWrapper>
            <ExternalLink label="LinkedIn" href={linkedIn} />
            <ExternalLink label="Download CV" href={cv} type="download" />
          </LinksWrapper>
        </TextBlock>
      </StyledContentWrapper>
    </Container>
  </Element>
)

const Container = styled.section`
  background-color: ${colors.grey};
  padding: 120px 0;

  ${mq.from.breakpoint.M`
    padding: 160px 0;
  `}
`

const StyledContentWrapper = styled(ContentWrapper)`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  ${mq.from.breakpoint.M`
    flex-direction: row;
  `}
`

const Img = styled(Image)`
  width: 50%;
  margin-bottom: 32px;
  border-bottom-right-radius: 80px;

  ${mq.from.breakpoint.M`
    width: 33.33333%;
    margin-right: 56px;
    margin-bottom: 0;
  `}
`

const TextBlock = styled.div`
  width: 100%;

  ${mq.from.breakpoint.M`
    width: 66.66666%;

  `}
`

const Title = styled.h2`
  ${textStyles.title};
`

const LinksWrapper = styled.div`
  display: flex;
  margin-top: 48px;

  > a {
    margin-right: 40px;
  }
`

export default About
