import React from "react"
import styled from "styled-components"
import { FluidObject } from "gatsby-image"
import BackgroundImage from "gatsby-background-image"

// Styles
import textStyles from "../styles/textStyles"
import colors from "../styles/colors"
import mq from "../styles/breakpoints"

// Components
import Button from "./atoms/Button"
import AnchorLink from "./atoms/AnchorLink"

interface IProps {
  title: string
  text: string
  buttonLabel: string
  image: {
    alt: string
    url: FluidObject | FluidObject[]
  }
  email: string
}

const Header = ({ title, text, buttonLabel, image, email }: IProps) => (
  <Container fluid={image.url} Tag="header">
    <Overlay />
    <TextWrapper>
      <PageTitle>{title}</PageTitle>
      <Intro dangerouslySetInnerHTML={{ __html: text }} />
      <ButtonWrapper>
        <Button label={email} href={`mailto:${email}`} />
        <AnchorLink label={buttonLabel} to="about" />
      </ButtonWrapper>
    </TextWrapper>
  </Container>
)

const Container = styled(BackgroundImage)`
  position: relative;
  padding: 120px 0;
  border-bottom-right-radius: 200px;
  overflow: hidden;

  ${mq.from.breakpoint.M`
    padding: 160px 0;
  `}

  ${mq.from.breakpoint.L`
    border-bottom-right-radius: 400px;
  `}
`

const Overlay = styled.div`
  z-index: 1;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    45deg,
    rgba(1, 22, 39, 1) 0%,
    rgba(1, 22, 39, 0.2) 100%
  );
`

const TextWrapper = styled.div`
  z-index: 2;
  position: relative;
  max-width: 960px;
  padding: 0 24px;
  color: ${colors.white};
  text-align: center;

  ${mq.from.breakpoint.M`
    text-align: left;
  `}

  ${mq.from.breakpoint.L`
    padding-left: 120px;
  `}
`

const PageTitle = styled.h1`
  ${textStyles.pageTitle};
`

const Intro = styled.div`
  margin-bottom: 32px;

  ${mq.from.breakpoint.M`
    margin-bottom: 56px;
  `}

  >p {
    ${textStyles.intro};
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > a {
    margin-bottom: 24px;
  }

  ${mq.from.breakpoint.S`

  `}

  ${mq.from.breakpoint.M`
    justify-content: flex-start;
    flex-direction: row;

    > a {
      margin-right: 32px;
      margin-bottom: 0;
    }
  `}
`

export default Header
